/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
    Grid,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControl,
    FormControlLabel
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { calculateDomain } from "Charts/LineChart";
import BarChart from "Charts/BarChart";
import call from "api/call";
import {internalapiCall} from "api/call";

import {
    dateToInt,
    getFirstDayOfYear,
    formattedDateToString,
    getTodayByNumber,
    goYearsBack,
    goMonthsBack,
    goWeeksBack,
    goDaysBack
} from "utils/dateFormatters";
import ExcelLike from "DataGrid/ExcelLike";
import { numberWithCommas } from "utils/numberManipulators";
import Widget from "components/Widgets/Widget";
import { setNumOfIntervalLabels, getValueInUnit } from "Charts/helpers";
import {
    ResponsiveContainer,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Label,
    ComposedChart,
    Bar
} from "recharts";
import { chartColorsArray } from "styles/variables";

const DECIMAL_PLACES = 3;
const numberOfLabels = 6;

const Chart = ({
    data,
    dataKeys: [x, y, unit],
    yRightAxisLabel,
    yLeftAxisLabel,
    showCertBars
}) => {
    const interval = setNumOfIntervalLabels(data.length, numberOfLabels);
    return (
        <ResponsiveContainer height="100%">
            <ComposedChart
                data={data}
                margin={{
                    right: 40,
                    left: 50
                }}
            >
                <XAxis
                    dataKey={x}
                    tickFormatter={(value) => `${formattedDateToString(value)}`}
                    interval={interval}
                    tickLine={false}
                    orientation="bottom"
                    padding={{ left: 0, right: 0 }}
                />
                <YAxis
                    domain={calculateDomain(data, y)}
                    orientation="left"
                    type="number"
                    tickLine={false}
                    tickFormatter={(value) => {
                        return unit
                            ? getValueInUnit(value, unit)
                            : numberWithCommas(value, DECIMAL_PLACES);
                    }}
                    yAxisId={"left"}
                >
                    <Label
                        value={yLeftAxisLabel}
                        angle={-90}
                        position="left"
                        dx={0}
                        dy={0}
                    />
                </YAxis>
                <Line
                    order={0}
                    dot={null}
                    activeDot={{ r: 8 }}
                    type="monotone"
                    dataKey={y}
                    stroke={chartColorsArray[0]}
                    yAxisId="left"
                />
                <Tooltip
                    labelFormatter={(value) => {
                        return `${formattedDateToString(value)}`;
                    }}
                    formatter={(value, label, dome) => {
                        return [`${numberWithCommas(value, 2)}`, label];
                    }}
                />
                <Legend />
                {showCertBars && (
                    <>
                        <YAxis
                            orientation="right"
                            type="number"
                            tickLine={false}
                            tickFormatter={(value) => {
                                return unit
                                    ? getValueInUnit(value, unit)
                                    : numberWithCommas(value, DECIMAL_PLACES);
                            }}
                            yAxisId={"right"}
                        >
                            <Label
                                value={yRightAxisLabel}
                                angle={90}
                                position="right"
                                dx={20}
                                dy={-50}
                            />
                        </YAxis>
                        <Bar
                            order={1}
                            fillOpacity={0.35}
                            dataKey={"Number_Of_Certificates"}
                            yAxisId={"right"}
                            fill={"#bbb"}
                        />
                    </>
                )}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

const dataOptions = [
    { label: "AUM", value: "AUM", type: "line" },
    { label: "NAV", value: "NAV", type: "line" },
    { label: "AFK", value: "Return", type: "line" }
];

const columns = [
    { field: "What_Year", headerName: "Year", flex: 1 },
    { field: "Jan", headerName: "Jan", flex: 1 },
    { field: "Feb", headerName: "Feb", flex: 1 },
    { field: "Mar", headerName: "Mar", flex: 1 },
    { field: "Apr", headerName: "Apr", flex: 1 },
    { field: "May", headerName: "May", flex: 1 },
    { field: "June", headerName: "Jun", flex: 1 },
    { field: "July", headerName: "Jul", flex: 1 },
    { field: "Aug", headerName: "Aug", flex: 1 },
    { field: "Sep", headerName: "Sep", flex: 1 },
    { field: "Oct", headerName: "Oct", flex: 1 },
    { field: "Nov", headerName: "Nov", flex: 1 },
    { field: "Dec", headerName: "Dec", flex: 1 },
    { field: "Year", headerName: "Total", flex: 1 }
];

const constructDateFilter = (chartData) => {
    let dateFilter = [
        {
            label: "1d",
            daysBack: goDaysBack(getTodayByNumber(), 1)
        },
        {
            label: "1u",
            daysBack: goWeeksBack(getTodayByNumber(), 1)
        },
        {
            label: "1m",
            daysBack: goMonthsBack(getTodayByNumber(), 1)
        },
        {
            label: "3m",
            daysBack: goMonthsBack(getTodayByNumber(), 3)
        },
        {
            label: "6m",
            daysBack: goMonthsBack(getTodayByNumber(), 6)
        },
        {
            label: "i år",
            daysBack: dateToInt(getFirstDayOfYear())
        },
        {
            label: "1 år",
            daysBack: goYearsBack(getTodayByNumber(), 1)
        },
        {
            label: "max",
            daysBack: getTodayByNumber()
        }
    ];

    const earliestDate = chartData[0].Date;
    const lastDate = chartData[chartData.length - 1].Date;

    dateFilter = dateFilter.map((filter) => {
        const notWithinBounds =
            filter.daysBack < earliestDate || filter.daysBack >= lastDate;
        return {
            ...filter,
            disabled: filter.label === "max" ? false : notWithinBounds
        };
    });

    return dateFilter;
};

const ChartWithFilter = ({
    originalChartData,
    filteredChartData,
    dataOption,
    dataKeys,
    filterByDate,
    showCertBars
}) => {
    const dateFilter = constructDateFilter(originalChartData);
    const [dateFilterKey, setDateFilterKey] = useState("max");

    return (
        <>
            {dataOption.type === "line" && (
                <div>
                    <div style={{ height: "300px" }}>
                        <Chart
                            data={filteredChartData}
                            dataKeys={dataKeys}
                            yLeftAxisLabel={dataOption.label}
                            yRightAxisLabel={"Number of Certificates"}
                            showCertBars={showCertBars}
                        />
                    </div>
                    <Box
                        flex={1}
                        display={"flex"}
                        justifyContent={"center"}
                        marginTop={"16px"}
                    >
                        <ButtonGroup variant="outlined">
                            {dateFilter.map((filter, index) => {
                                return (
                                    <Button
                                        disabled={filter.disabled}
                                        variant={
                                            filter.label === dateFilterKey
                                                ? "contained"
                                                : "outlined"
                                        }
                                        key={index}
                                        onClick={() => {
                                            const newDateFilterKey =
                                                filter.label;
                                            setDateFilterKey(newDateFilterKey);

                                            if (newDateFilterKey === "max") {
                                                filterByDate(0);
                                            } else {
                                                filterByDate(filter.daysBack);
                                            }
                                        }}
                                    >
                                        {filter.label}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Box>
                </div>
            )}

            {dataOption.type === "bar" && (
                <BarChart data={filteredChartData} dataKeys={dataKeys} />
            )}
        </>
    );
};

const HistoricalNAV = ({ PortfolioName, CustomerID, OrganisationID }) => {
    const params = useParams();
    const [chartData, setChartData] = useState([]);
    const [dataKeys, setDataKeys] = useState(["Date", "AUM", "DKK"]);
    const [dataOption, setDataOption] = useState(dataOptions[0]);
    const [originalData, setOriginalData] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCertBars, setShowCertBars] = useState(false);

    useEffect(() => {
        const getHistorical = async () => {
            setLoading(true);
            const payload = {
                PortfolioName: PortfolioName,
                CustomerID: CustomerID,
                MasterUserID: OrganisationID
            };

            try {
                const response = await internalapiCall("POST", `iRisk_Backend_Proxy/iRisk_NAVOfficialDirect`, payload);

                if (response.status === 200) {
                    let newChartData = [];
                    newChartData = response.data.NAV;

                    const rows =
                        response.data.TableReturnAsset.What_Year.map(
                            (year, index) => {
                                const monthNumberFunctionGenerator = (
                                    monthData,
                                    index
                                ) => {
                                    return (month) => {
                                        return {
                                            [month]:
                                                numberWithCommas(
                                                    monthData[month][index],
                                                    3
                                                ) || null
                                        };
                                    };
                                };

                                const getMonthDataWithCommas =
                                    monthNumberFunctionGenerator(
                                        response.data.TableReturnAsset,
                                        index
                                    );

                                const months = [
                                    "Apr",
                                    "Aug",
                                    "Dec",
                                    "Feb",
                                    "Jan",
                                    "July",
                                    "June",
                                    "Mar",
                                    "May",
                                    "Nov",
                                    "Oct",
                                    "Sep"
                                ];

                                return {
                                    id: `id-${year}-${index}`,
                                    ...months.reduce(
                                        (acc, month) => ({
                                            ...acc,
                                            ...getMonthDataWithCommas(month)
                                        }),
                                        {}
                                    ),
                                    What_Year: year,
                                    ...getMonthDataWithCommas("Year")
                                };
                            }
                        );

                    setRows(rows);

                    setChartData(newChartData);
                    setOriginalData(newChartData);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        };
        getHistorical();
    }, [params]);

    const filterByDate = useCallback(
        (newValue) => {
            if (originalData.length) {
                const newData = originalData.filter(
                    (el) => el.Date >= newValue
                );
                setChartData(newData);
            }
        },
        [originalData]
    );

    const handleChangeDataOption = (option) => {
        const newOption = dataOptions.find((dataOption) => {
            return dataOption.value === option;
        });
        setDataOption(newOption);
        setDataKeys(["Date", newOption.value, "DKK"]);
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Widget
                        headline={"NAV"}
                        renderActions={() => [
                            <ButtonGroup
                                variant="outlined"
                                key={"button-group-invested"}
                            >
                                {dataOptions.map((option) => (
                                    <Button
                                        key={option.label}
                                        onClick={handleChangeDataOption.bind(
                                            this,
                                            option.value
                                        )}
                                        color="primary"
                                        variant={
                                            dataOption.value === option.value
                                                ? "contained"
                                                : "outlined"
                                        }
                                    >
                                        {option.label}
                                    </Button>
                                ))}
                            </ButtonGroup>,
                            <FormControl
                                key={"CER"}
                                sx={{ marginLeft: "16px" }}
                            >
                                <FormControlLabel
                                    control={<Checkbox  
                                        value={showCertBars}
                                        onChange={()=>{
                                        setShowCertBars(!showCertBars)
                                    }}/>}
                                    label="CER"
                                />
                            </FormControl>
                        ]}
                    >
                        {chartData.length > 0 ? (
                            <ChartWithFilter
                                originalChartData={originalData}
                                filteredChartData={chartData}
                                dataOption={dataOption}
                                dataKeys={dataKeys}
                                filterByDate={filterByDate}
                                showCertBars={showCertBars}
                            />
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Widget>
                </Grid>
                <Grid item xs={12}>
                    <Widget headline={"Return"}>
                        <ExcelLike
                            rows={rows}
                            columns={columns}
                            loading={loading}
                        />
                    </Widget>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HistoricalNAV;
