export const REPORT_TYPES = {
    DASHBOARD: "dashboard",
    APP: "app",
    WEBSITE: "website",
    PDF: "pdf"
};

export const REPORT_TYPE_OPTIONS = [
    { id: REPORT_TYPES.DASHBOARD, name: "Dashboard" },
    // { id: REPORT_TYPES.APP, name: "App" },
    { id: REPORT_TYPES.WEBSITE, name: "Website" },
    { id: REPORT_TYPES.PDF, name: "PDF" }
];